<template>
    <div class="tab-text">
        <div class="vx-col w-1/2 mb-6 mt-3 ml-5">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/4 w-full">
                    <span>Warehouse Code</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <multiselect 
                        class="selectExample" 
                        v-model="selectedWarehouse" 
                        :options="optionWarehouse"
                        :multiple="false" 
                        :allow-empty="true" 
                        :group-select="false" 
                        :max-height="500" 
                        :limit="3"
                        placeholder="Type to search" 
                        :searchable="true" 
                        :custom-label="customLableWarehouse"
                        @select="handleBTNWarehouse()"
                        :select-label="''"
                        deselect-label=""
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/4 w-full">
                    <span>Transaction Date</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-input class="w-full" v-model="date" type="date" v-validate="'required'" />
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-2/3 w-full">
                    <span></span>
                </div>
                <vs-button @click="onClickBtnFilter" size="medium" color="primary" icon-pack="feather"
                    icon="icon-search" title="Search">
                    Filter
                </vs-button>
                <vs-button @click="onClickBtnClear" size="medium" class="ml-2" color="primary" icon-pack="feather"
                    icon="icon-search" title="Search">
                    Clear
                </vs-button>
            </div>
        </div>

        <div class="vx-row mb-12">
            <div class="core vx-col md:w-1/1 w-full mb-base">
                <data-table 
                    :baseUrl="baseUrl"
                    :status="7"
                    :warehouse="selectedWarehouse.id"
                    :date="date"
                    :isFilter="isfilter"
                    @isFilter="setFilter"
                ></data-table>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from "../DataTable";

export default {
    components: {
        DataTable,
    },
    data() {
        return {
            baseUrl: "api/wms/v1/inter-storage",
            date: "",
            defaultWhFilter: 0,
            optionWarehouse: [
                {
                    id: 0,
                    code: null,
                    name: null,
                }
            ],
            selectedWarehouse: {},
            isfilter: false,
        };
    },
    methods: {
        setFilter(val) {
            this.isfilter = val;
        },
        onClickBtnFilter() {
            this.isfilter = true;
        },
        onClickBtnClear() {
            this.selectedWarehouse = {};
            this.date = "";
            this.isfilter = true;
        },
        getWarehouse() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/inter-storage/get-warehouse-filter", {
                params: {
                    order: "name",
                    sort: "asc",
                },
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.records) {
                        this.defaultWhFilter = resp.data.default_warehouse
                        this.optionWarehouse = resp.data.records

                        if (this.defaultWhFilter != 0) {
                            this.optionWarehouse.forEach(val => {
                                if (val.id == this.defaultWhFilter) {
                                    this.selectedWarehouse = val
                                }
                            })
                        } else {
                            this.selectedWarehouse = resp.data.records[0]
                        }
                    } else {
                        this.optionWarehouse = [];
                        this.selectedWarehouse = {};
                    }
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        customLableWarehouse({ code, name }) {
            return `${code} - ${name}`;
        },
    },
    mounted() {
        this.getWarehouse();
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>